<template>
  <div class="payments-history">
    <div class="container">
      <base-heading class="dashboard-heading" bold="bold">
        {{ $t("paymentsHistory.title") }}
      </base-heading>

      <base-table
        class="payments-history__table"
        :data="payments"
        :columns="columns"
      >
        <template slot-scope="{ row, index }">
          <td>{{ index + 1 }}.</td>
          <td>
            <course-thumbnail
              v-bind="row.course"
              :additionalDetails="`${$t('level')} ${row.course.difficulty}`"
              :mode="row.paymentStatus === 'paid' ? 'watch' : 'check'"
            />
          </td>
          <td>
            {{ formattedDate(row.time) }}
          </td>
          <td>
            {{ row.payPalOrderId }}
          </td>
          <td>
            <img
              src="assets/images/paypal.png"
              alt
              class="payments-history__table-paypal"
            />
          </td>
          <td>
            <base-price :price="row.price * ((100-row.promoRate) / 100)" class="course-tile__price"/>
            <span v-if="row.promoRate > 0">(-{{row.promoRate}}%)</span>
          </td>
          <td>
            <span
              class="payments-history__table-status"
              :class="[
                row.paymentStatus === 'waiting_for_payment'
                  ? 'payments-history__table-status--waiting'
                  : 'payments-history__table-status--paid'
              ]"
            >
              {{ getPaymentStatus(row.paymentStatus) }}
            </span>
            <base-button
              v-if="row.paymentStatus === 'waiting_for_payment'"
              @click="makeOrderPayment(row.id)"
              class="payments-history__table-status-button"
            >
              {{ $t("paymentsHistory.pay") }}
            </base-button>
            <base-button
              v-if="row.paymentStatus === 'paid'"
              @click="getCourseInvoice(row.id)"
              class="payments-history__table-status-button"
            >
              {{ $t("paymentsHistory.invoice") }}
            </base-button>
            <br/>
            <div :id="'single-course-paypal-button-container-'+row.id"
            style="margin-top: 32px;"></div>
          </td>
<!--          <td>-->
<!--            <base-price :price="row.price * ((100-row.commissionRate-row.promoRate) / 100)" class="course-tile__price"/>-->
<!--          </td>-->
        </template>
      </base-table>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import course from '@/api/services/course';
import payment from '@/api/services/payment';

export default {
  name: 'MyPaymentsView',
  data: () => ({
    payments: [],
    showPaymentModal: false,
    buttonsIds: [],
  }),
  computed: {
    columns() {
      return this.$t('paymentsHistory.columns').map((column, index) => ({
        key: index,
        label: column,
      }));
    },
  },
  methods: {
    formattedDate(date) {
      return dayjs(date).format('DD/MM/YYYY');
    },
    getPaymentStatus(status) {
      return status === 'waiting_for_payment'
        ? this.$t('paymentsHistory.waiting')
        : this.$t('paymentsHistory.bought');
    },
    async makeOrderPayment(id) {
      this.showPaymentModal = true;
      try {
        const { data } = await payment.makePayment(id);
        const token = data.link.toString().substr(-17);
        if (!this.buttonsIds.includes(id)) {
          this.buttonsIds.push(id);
          // eslint-disable-next-line no-undef
          paypal.Buttons({
            async createOrder() {
              return token;
            },
            onApprove() {
              this.showPaymentModal = false;
              window.location.href = `/payment-status?token=${token}`;
            },
          }).render(`#single-course-paypal-button-container-${id}`);
        }
      } catch (err) {
        this.$store.dispatch('notification/spawn', {
          errorMessage: err.response.data.message,
          type: 'error',
        });

        this.$store.dispatch('ui/setPreloader', false);
      }
    },
    async getCourseInvoice(id) {
      try {
        const { data } = await payment.getInvoice(id);
        const blob = new Blob([data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = fileURL;
        link.download = 'invoice';

        document.body.append(link);

        link.click();
        link.remove();

        window.URL.revokeObjectURL(fileURL);
      } catch (err) {
        console.log(err);
        this.$store.dispatch('notification/spawn', {
          errorMessage: err.response.data.message,
          type: 'error',
        });
      }
    },
  },
  async created() {
    this.$store.dispatch('ui/setPreloader', true);

    try {
      const { data } = await course.getOwnedCourses();
      this.payments = data;
    } catch (err) {
      this.$store.dispatch('notification/spawn', {
        errorMessage: err.response.data.message,
        type: 'error',
      });
    } finally {
      this.$store.dispatch('ui/setPreloader', false);
    }
  },
};
</script>

<style lang="scss" src="./MyPayments.scss"/>
